export function accordion() {
  //アコーディオンをクリックした時の動作
  $('.c-accordion_area > li').on('click', function() {//タイトル要素をクリックしたら
    $(this).find('.c-accordion_box').slideToggle();//アコーディオンの上下動作

    if($(this).find(".c-accordion_title").hasClass('close')){//タイトル要素にクラス名closeがあれば
      $(this).find(".c-accordion_title").removeClass('close');
    }else{
      $(this).find(".c-accordion_title").addClass('close');
    }
  });
}
